/**
 * Import SASS 
 * Combining files into one, adds them in sequence
 */
/**
 * Global Variables
 */
/* Breakpoint Screen Sizes */
/* Breakpoint Font Root Sizes */
/* Colors */
/* Official Social Media Colors */
/* Fonts */
/**
 * Mixins
 */
/**
 * Media Queries
 */
/**
 * hoverTouch.js implementation
 */
/**
 * Convert pixel sizes to REM
 * @arg  {string}  $pixels  The pixel size to convert to REM
 * @arg  {string}  $root    The root size to convert by
 * @return  {string}  the calculated REM size
 */
/**
 * Convert pixel sizes to VW
 * @arg  {string}  $pixels  The pixel size to convert to REM
 * @arg  {string}  $width   The width to convert by
 * @return  {string}  the calculated VW size
 */
/**
 * Custom Scrollbar Styling
 * @param  {integer} $size       Enter in the increments for size of the scrollbar
 *                               EX: ".5em"
 * @param  {string}  $primary    Enter in the color desired for the primary color
 * @param  {string}  $secondary  Enter in the color desired for the secondary color
 */
/**
 * Imports
 * Add external files into combined sass file
 */
@font-face {
  font-family: 'californiamedium';
  src: url("./fonts/california-webfont.woff2") format("woff2"), url("./fonts/california-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/**
 * Keyframe Animations
 */
@keyframes show {
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: -2; }
  1% {
    z-index: 3; }
  100% {
    opacity: 1;
    visibility: visible;
    z-index: 3; } }

@keyframes hide {
  0% {
    opacity: 1;
    visibility: visible;
    z-index: 3; }
  99% {
    z-index: 3; }
  100% {
    opacity: 0;
    visibility: hidden;
    z-index: -2; } }

@keyframes rotateClockwise {
  100% {
    transform: rotate(360deg); } }

/**
 * Global
 */
html, body {
  font-size: 16px; }
  @media only screen and (min-width: 320px) {
    html, body {
      font-size: 1.33333vw; } }
  @media only screen and (min-width: 751px) {
    html, body {
      font-size: 0.7874vw; } }
  @media only screen and (min-width: 1271px) {
    html, body {
      font-size: 0.625vw; } }

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  font-family: 'Domine', serif;
  overflow-x: hidden;
  color: #fff;
  background-color: #000; }

html {
  margin-top: 0 !important; }

#site {
  position: relative;
  width: 100vw;
  height: 100vh; }

#siteHolder {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto; }

img {
  width: 100%;
  height: auto; }

h1, h2, h3 {
  margin: 0 !important;
  font-weight: normal; }

p {
  margin: 1rem 0; }
  @media only screen and (min-width: 751px) {
    p {
      margin: 1rem 0; } }
  @media only screen and (min-width: 1271px) {
    p {
      margin: 2rem 0; } }

a:link {
  color: #24293D;
  text-decoration: none; }

a:visited {
  color: #24293D;
  text-decoration: none; }

a:hover {
  color: #3A3F5D;
  text-decoration: none; }

a:active {
  color: #24293D;
  text-decoration: none; }

.screen-reader-text {
  /* Reusable, toolbox kind of class */
  position: absolute;
  top: -9999px;
  left: -9999px; }

#navLogo {
  position: fixed;
  z-index: 1003;
  width: 15rem;
  height: 15rem;
  left: 2rem;
  top: -0.2rem;
  border: 0.2rem solid #000; }
  @media only screen and (min-width: 751px) {
    #navLogo {
      width: 13rem;
      height: 13rem;
      left: 3rem;
      top: -0.2rem;
      border: 0.2rem solid #000; } }
  @media only screen and (min-width: 1271px) {
    #navLogo {
      width: 13rem;
      height: 13rem;
      left: 3rem;
      top: -0.2rem;
      border: 0.2rem solid #000; } }

#navLogo img {
  width: 100%;
  height: auto; }

#navMenuBtn {
  position: fixed;
  z-index: 1001;
  color: #FFF;
  background-color: #000;
  font-size: 4rem;
  padding: 4rem;
  visibility: visible;
  right: 1.8rem;
  top: 1.8rem; }
  @media only screen and (min-width: 751px) {
    #navMenuBtn {
      visibility: hidden;
      font-size: 1.8rem;
      padding: 0 1rem; } }
  @media only screen and (min-width: 1271px) {
    #navMenuBtn {
      visibility: hidden;
      font-size: 1.8rem;
      padding: 0 1rem; } }

#navMenuCloseBtn {
  position: fixed;
  z-index: 1003;
  color: #fff;
  background-color: #000;
  font-size: 5.5rem;
  padding: 3rem;
  visibility: hidden;
  right: 1.8rem;
  top: 1.8rem; }
  @media only screen and (min-width: 751px) {
    #navMenuCloseBtn {
      font-size: 1.8rem;
      padding: 0 1rem; } }
  @media only screen and (min-width: 1271px) {
    #navMenuCloseBtn {
      font-size: 1.8rem;
      padding: 0 1rem; } }

#navButtons {
  position: fixed;
  z-index: 1002;
  background-color: #000;
  display: none;
  opacity: 0;
  right: 0rem;
  top: 0rem;
  padding-top: 24rem;
  width: 100%;
  height: 100%; }
  @media only screen and (min-width: 751px) {
    #navButtons {
      display: block;
      opacity: 1;
      right: 1.8rem;
      top: 1.8rem;
      padding-top: 0;
      width: auto;
      height: auto; } }
  @media only screen and (min-width: 1271px) {
    #navButtons {
      display: block;
      opacity: 1;
      right: 0rem;
      top: 2.5rem;
      padding-top: 0;
      width: auto;
      height: auto; } }

#navBtnHolder {
  display: inline-block;
  vertical-align: top; }

#socialBtnHolder {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: 100%;
  padding-top: 4rem; }
  @media only screen and (min-width: 751px) {
    #socialBtnHolder {
      width: auto;
      padding-top: 0; } }
  @media only screen and (min-width: 1271px) {
    #socialBtnHolder {
      width: auto;
      padding-top: 0; } }

.navBtn {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-family: 'californiamedium', serif;
  text-transform: uppercase;
  color: #fff;
  background-color: #000;
  font-size: 7rem;
  letter-spacing: 0.1rem;
  padding: 2rem 0;
  width: 100vw; }
  @media only screen and (min-width: 751px) {
    .navBtn {
      font-size: 1.9rem;
      letter-spacing: 0.1rem;
      padding: 1.6rem;
      width: auto; } }
  @media only screen and (min-width: 1271px) {
    .navBtn {
      font-size: 2.4rem;
      letter-spacing: 0.1rem;
      padding: 2rem;
      width: auto; } }

.navBtn:hover, .socialBtn:hover {
  color: #000;
  background-color: #fff;
  text-decoration: none; }

.socialBtn {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  background-color: #000;
  font-size: 7rem;
  padding: 0 2.5rem; }
  @media only screen and (min-width: 751px) {
    .socialBtn {
      font-size: 2.2rem;
      padding: 1.6rem 0.8rem; } }
  @media only screen and (min-width: 1271px) {
    .socialBtn {
      font-size: 2.7rem;
      padding: 2rem 1rem; } }

.headerImg, .postHeaderImg {
  display: block;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto 100%;
  width: 75rem; }
  @media only screen and (min-width: 751px) {
    .headerImg, .postHeaderImg {
      background-size: 100% auto;
      width: 127rem; } }
  @media only screen and (min-width: 1271px) {
    .headerImg, .postHeaderImg {
      background-size: 100% auto;
      width: 160rem; } }

.headerImg {
  height: 37.5rem; }
  @media only screen and (min-width: 751px) {
    .headerImg {
      height: 48rem; } }
  @media only screen and (min-width: 1271px) {
    .headerImg {
      height: 60rem; } }

.pageHeader {
  display: inline-block;
  background-color: #fff;
  color: #000;
  text-align: left;
  width: 100%; }

.pageInfo {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  background-color: #000; }

.pagePoster {
  width: 20rem;
  margin: 3rem 3rem 1rem 3rem; }
  @media only screen and (min-width: 751px) {
    .pagePoster {
      width: 15rem;
      margin: 3rem 3rem 1rem 3rem; } }
  @media only screen and (min-width: 1271px) {
    .pagePoster {
      width: 15rem;
      margin: 3rem 5rem 1rem 5rem; } }

.pageLinks {
  margin-bottom: 2.5rem; }
  @media only screen and (min-width: 751px) {
    .pageLinks {
      margin-bottom: 2.5rem; } }
  @media only screen and (min-width: 1271px) {
    .pageLinks {
      margin-bottom: 2.5rem; } }

.pageLinks a {
  display: inline-block;
  font-family: 'Ultra', serif;
  background-color: #fff;
  color: #000;
  text-align: center;
  font-size: 1.8rem;
  line-height: 2.1rem;
  padding: 1rem;
  margin: 0.5rem 1rem;
  width: 18rem;
  transition: all 0.3s; }
  @media only screen and (min-width: 751px) {
    .pageLinks a {
      font-size: 1.4rem;
      line-height: 1.8rem;
      padding: 1.5rem;
      margin: 0.5rem 1rem;
      width: 13rem; } }
  @media only screen and (min-width: 1271px) {
    .pageLinks a {
      font-size: 1.7rem;
      line-height: 2.1rem;
      padding: 1.5rem;
      margin: 0.5rem 1rem;
      width: 13rem; } }

.pageLinks a:hover {
  background-color: #000;
  color: #fff; }

.pageBody, .pageInfoBody {
  color: #000;
  background-color: #fff;
  font-size: 2.1rem;
  line-height: 3rem;
  padding: 3rem; }
  @media only screen and (min-width: 751px) {
    .pageBody, .pageInfoBody {
      font-size: 2.1rem;
      line-height: 3rem;
      padding: 3rem; } }
  @media only screen and (min-width: 1271px) {
    .pageBody, .pageInfoBody {
      font-size: 2.8rem;
      line-height: 4rem;
      padding: 5rem; } }

.pageBody {
  vertical-align: middle;
  text-align: center;
  color: #000;
  background-color: #fff; }

.pageInfoBody {
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

.pageSecTitle {
  font-family: 'Ultra', serif;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  font-size: 4.5rem;
  letter-spacing: 0.2rem;
  padding: 2rem 0; }
  @media only screen and (min-width: 751px) {
    .pageSecTitle {
      font-size: 3.8rem;
      letter-spacing: 0.2rem;
      padding: 1.6rem; } }
  @media only screen and (min-width: 1271px) {
    .pageSecTitle {
      font-size: 4.8rem;
      letter-spacing: 0.2rem;
      padding: 2rem; } }

.pageSecTitleLink a {
  display: block;
  color: #fff;
  background-color: #000;
  transition: all 0.3s; }

.pageSecTitleLink a:hover {
  color: #000;
  background-color: #fff; }

.pageRow {
  text-align: center;
  background-color: #000; }

.galleryImg {
  display: inline-block;
  vertical-align: top;
  cursor: pointer; }

.pageThumbInfo {
  display: inline-block;
  vertical-align: top;
  width: 75rem; }
  @media only screen and (min-width: 751px) {
    .pageThumbInfo {
      width: 31.75rem; } }
  @media only screen and (min-width: 1271px) {
    .pageThumbInfo {
      width: 40rem; } }

.pageThumbVert {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: 75rem; }
  @media only screen and (min-width: 751px) {
    .pageThumbVert {
      width: 31.75rem; } }
  @media only screen and (min-width: 1271px) {
    .pageThumbVert {
      width: 40rem; } }

.pageThumbHorz {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: 75rem; }
  @media only screen and (min-width: 751px) {
    .pageThumbHorz {
      text-align: left;
      width: 63.5rem; } }
  @media only screen and (min-width: 1271px) {
    .pageThumbHorz {
      text-align: left;
      width: 80rem; } }

.pageThumbSm {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: 25rem; }
  @media only screen and (min-width: 751px) {
    .pageThumbSm {
      width: 15.7rem; } }
  @media only screen and (min-width: 1271px) {
    .pageThumbSm {
      width: 20rem; } }

.pageThumbRect {
  display: inline-block;
  overflow: hidden;
  width: 75rem;
  height: 37.5rem; }
  @media only screen and (min-width: 751px) {
    .pageThumbRect {
      width: 31.75rem;
      height: 15.875rem; } }
  @media only screen and (min-width: 1271px) {
    .pageThumbRect {
      width: 40rem;
      height: 20rem; } }

.pageThumbSq {
  display: inline-block;
  overflow: hidden;
  width: 75rem;
  height: 75rem; }
  @media only screen and (min-width: 751px) {
    .pageThumbSq {
      width: 31.75rem;
      height: 31.75rem; } }
  @media only screen and (min-width: 1271px) {
    .pageThumbSq {
      width: 40rem;
      height: 40rem; } }

.pageThumbSqSm {
  display: inline-block;
  overflow: hidden;
  width: 25rem;
  height: 25rem; }
  @media only screen and (min-width: 751px) {
    .pageThumbSqSm {
      width: 16rem;
      height: 16rem; } }
  @media only screen and (min-width: 1271px) {
    .pageThumbSqSm {
      width: 20rem;
      height: 20rem; } }

.pageThumbTall {
  display: inline-block;
  overflow: hidden;
  width: 75rem;
  height: 112.5rem; }
  @media only screen and (min-width: 751px) {
    .pageThumbTall {
      width: 31.75rem;
      height: 47.625rem; } }
  @media only screen and (min-width: 1271px) {
    .pageThumbTall {
      width: 40rem;
      height: 60rem; } }

.pageThumbTitle {
  font-family: 'Ultra', serif;
  color: #fff;
  background-color: #000;
  font-size: 3.6rem;
  line-height: 4rem;
  padding: 3rem 5rem; }
  @media only screen and (min-width: 751px) {
    .pageThumbTitle {
      font-size: 1.7rem;
      line-height: 2rem;
      padding: 0.8rem 1.6rem; } }
  @media only screen and (min-width: 1271px) {
    .pageThumbTitle {
      font-size: 2.1rem;
      line-height: 2.4rem;
      padding: 1rem 2rem; } }

.pageThumbDate {
  color: #fff;
  font-family: 'Domine', serif;
  font-size: 3rem;
  line-height: 4.8rem;
  padding-top: 1rem; }
  @media only screen and (min-width: 751px) {
    .pageThumbDate {
      font-size: 1.3rem;
      line-height: 1.9rem;
      padding-top: 0.8rem; } }
  @media only screen and (min-width: 1271px) {
    .pageThumbDate {
      font-size: 1.6rem;
      line-height: 2.8rem;
      padding-top: 1rem; } }

.pageThumbBody, .postBody {
  color: #fff;
  background-color: #000;
  font-size: 2.4rem;
  line-height: 3.3rem;
  padding: 0 4rem 4rem 4rem; }
  @media only screen and (min-width: 751px) {
    .pageThumbBody, .postBody {
      font-size: 1.3rem;
      line-height: 2.2rem;
      padding: 0 1.6rem 1.6rem 1.6rem; } }
  @media only screen and (min-width: 1271px) {
    .pageThumbBody, .postBody {
      font-size: 1.6rem;
      line-height: 2.8rem;
      padding: 0 2rem 2rem 2rem; } }

#postOverlay {
  background-color: #000;
  display: none;
  opacity: 0;
  position: fixed;
  z-index: 1009;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto; }

.postContent {
  display: none;
  opacity: 0;
  position: fixed;
  z-index: 1010;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto; }

.postImg {
  position: absolute;
  vertical-align: middle;
  text-align: center;
  width: 90vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  @media only screen and (min-width: 751px) {
    .postImg {
      width: 127rem; } }

.postImgTall {
  position: absolute;
  vertical-align: middle;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90vh; }
  .postImgTall img {
    width: auto;
    height: 100%; }

.rightArrow, .leftArrow, .postClose {
  color: #fff;
  opacity: .4;
  cursor: pointer;
  position: fixed;
  font-size: 5rem;
  transition: all 0.3s; }
  @media only screen and (min-width: 751px) {
    .rightArrow, .leftArrow, .postClose {
      font-size: 5rem; } }
  @media only screen and (min-width: 1271px) {
    .rightArrow, .leftArrow, .postClose {
      font-size: 6rem; } }

.rightArrow:hover, .leftArrow:hover, .postClose:hover {
  opacity: .8; }

.rightArrow {
  display: none;
  bottom: 3rem;
  right: 20rem; }
  @media only screen and (min-width: 751px) {
    .rightArrow {
      display: block;
      bottom: 50%;
      right: 3rem; } }
  @media only screen and (min-width: 1271px) {
    .rightArrow {
      display: block;
      bottom: 50%;
      right: 3rem; } }

.leftArrow {
  display: none;
  bottom: 3rem;
  left: 20rem; }
  @media only screen and (min-width: 751px) {
    .leftArrow {
      display: block;
      bottom: 50%;
      left: 3rem; } }
  @media only screen and (min-width: 1271px) {
    .leftArrow {
      display: block;
      bottom: 50%;
      left: 3rem; } }

.postClose {
  top: 3rem;
  right: 3rem;
  transition: all 0.3s; }
  @media only screen and (min-width: 751px) {
    .postClose {
      top: 3rem;
      right: 3rem; } }
  @media only screen and (min-width: 1271px) {
    .postClose {
      top: 3rem;
      right: 3rem; } }

.pageShare {
  margin: 0.5rem 0; }
  @media only screen and (min-width: 751px) {
    .pageShare {
      margin: 0.5rem 0; } }
  @media only screen and (min-width: 1271px) {
    .pageShare {
      margin: 0.5rem 0; } }

.videos {
  cursor: pointer; }

#videoOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1005;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0; }

#videoOverlayClose {
  cursor: pointer;
  color: #fff;
  font-family: 'Khula', sans-serif;
  font-weight: 800;
  top: 3rem;
  right: 3rem;
  font-size: 8rem;
  position: absolute; }
  @media only screen and (min-width: 751px) {
    #videoOverlayClose {
      top: 3rem;
      right: 3rem;
      font-size: 5rem; } }
  @media only screen and (min-width: 1271px) {
    #videoOverlayClose {
      top: 3rem;
      right: 3rem;
      font-size: 5rem; } }

.videoPlayerHolder {
  width: 100vw;
  height: 100vh; }

.videoPlayer {
  position: absolute;
  vertical-align: middle;
  text-align: center;
  width: 70rem;
  height: 39.375rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  @media only screen and (min-width: 751px) {
    .videoPlayer {
      width: 100rem;
      height: 56.25rem; } }
  @media only screen and (min-width: 1271px) {
    .videoPlayer {
      width: 100rem;
      height: 56.25rem; } }

#footer {
  font-family: 'Khula', sans-serif;
  font-weight: 400;
  width: 100vw;
  color: #fff;
  text-align: center;
  background-color: #000;
  padding: 2rem 0;
  font-size: 1.8rem; }
  @media only screen and (min-width: 751px) {
    #footer {
      padding: 2rem 0;
      font-size: 1.2rem; } }
  @media only screen and (min-width: 1271px) {
    #footer {
      padding: 2rem 0;
      font-size: 1.2rem; } }

#footer a:link {
  color: #b6b6b6;
  text-decoration: none; }
  @media only screen and (min-width: 751px) {
    #footer a:link {
      color: #b6b6b6; } }
  @media only screen and (min-width: 1271px) {
    #footer a:link {
      color: #b6b6b6; } }

#footer a:visited {
  color: #b6b6b6;
  text-decoration: none; }
  @media only screen and (min-width: 751px) {
    #footer a:visited {
      color: #b6b6b6; } }
  @media only screen and (min-width: 1271px) {
    #footer a:visited {
      color: #b6b6b6; } }

#footer a:hover {
  color: #FFF;
  text-decoration: underline; }
  @media only screen and (min-width: 751px) {
    #footer a:hover {
      color: #FFF; } }
  @media only screen and (min-width: 1271px) {
    #footer a:hover {
      color: #FFF; } }

#footer a:active {
  color: #b6b6b6;
  text-decoration: none; }
  @media only screen and (min-width: 751px) {
    #footer a:active {
      color: #b6b6b6; } }
  @media only screen and (min-width: 1271px) {
    #footer a:active {
      color: #b6b6b6; } }
